"use client"
import Carousel from '@/ui/carousel/carousel';
import Container from '@/ui/container';
import Image from 'next/image'
import React from 'react'
import { SwiperSlide } from 'swiper/react';
export default function PatnersScroll({ props, arrows }: any) {

    const breakpoints = {
        "1720": {
            slidesPerView: 5,
            spaceBetween: 28,
            //        touchRatio: 0,
        },
        "1400": {
            slidesPerView: 5,
            spaceBetween: 28,
        },
        "1025": {
            slidesPerView: 5,
            spaceBetween: 28,
        },
        "768": {
            slidesPerView: 4,
            spaceBetween: 28,
        },
        "500 ": {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        "360": {
            slidesPerView: 3,
            spaceBetween: 12,
        },
    };

    return (
        <Container className="py-2 overflow-hidden mt-10 xs:mt-3 sm:mt-3 relative">
            <Carousel
                onTouch={false}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                    reverseDirection: false,
                }}
                loop={true}
                className={`mx-0 pagination-center col-span-12 self-end xs:h-full border-b border-t border-dashed py-5`}
                buttonClassName={""}
                spaceBetween={32}
                swiperNav={arrows}
                buttonPosition={"inside"}
                centeredSlides={false}
                pagination={false}
                breakpoints={breakpoints}
            >
                <div className="flex items-center justify-center md:justify-start border-b border-t py-5 m-auto ">
                    {props?.partnersList?.map((image: any, index: any) => (
                        <SwiperSlide key={index} virtualIndex={index} className="flex justify-center">
                            <div className='flex justify-center items-center testin' key={index + image?.id + 'partners_lists'}><Image src={`/images/${image?.image}`} alt={image.alt} width={170} height={75} className='xs:w-28' /></div>
                        </SwiperSlide>
                    ))}
                </div>
            </Carousel>
        </Container>
    )
}
